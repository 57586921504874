import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Cover from '../components/Cover'
import Gallery from '../components/Gallery'

import { Container } from '../styles/PageLayout'

export default function HomePage({ data }) {
  const items = data.items.nodes

  return (
    <>
      <SEO title="Home" />
      <Container column mobileColumn>
        <Cover />
        <Gallery items={items} isHomePage />
      </Container>
    </>
  )
}

export const query = graphql`
  query {
    items: allSanityItem(sort: { fields: _createdAt, order: DESC }, limit: 9) {
      totalCount
      nodes {
        id
        name
        description {
          _key
          _type
          children {
            _key
            _type
            marks
            text
          }
          style
        }
        slug {
          current
        }
        images {
          asset {
            fluid(maxWidth: 350) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
