import styled, { keyframes } from 'styled-components'

import breakpoints from '../constants/breakpoints'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const slideLeftText = keyframes`
  0% {
    transform: translateX(4vw);
  }
  100% {
    transform: translateX(0);
  }
`

const slideLeftImage = keyframes`
  0% {
    transform: translateX(6vw);
  }
  100% {
    transform: translateX(0);
  }
`

const CoverLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CoverHeadline = styled.div`
  padding: 5% 4vw 6%;
  line-height: 1.5;
  color: ${({ theme }) => theme.textPrimary};

  h1 {
    opacity: 0;
    transform: translateX(4vw);
    animation: ${fadeIn} 0.6s ease-out forwards,
      ${slideLeftText} 1.4s cubic-bezier(0.02, 0.98, 0.36, 1) forwards;
    animation-delay: 1.1s;
  }

  b {
    font-weight: 700;
  }
`

const CoverImage = styled.div`
  position: relative;
`

const Image = styled.div`
  position: relative;
  width: 75vw;
  margin-left: auto;
  opacity: 0;
  transform: translateX(6vw);
  animation: ${fadeIn} 0.6s ease-out forwards,
    ${slideLeftImage} 1.6s cubic-bezier(0.02, 0.98, 0.36, 1) forwards;
  animation-delay: 1.1s;

  @media (max-width: ${breakpoints.screenLG}) {
    width: 85vw;
  }

  img {
    object-fit: cover;
    width: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
    opacity: 0.2;
    background-color: ${({ theme }) => theme.coverImgOverlay};
    z-index: 1;
  }
`

export { CoverLayout, CoverHeadline, CoverImage, Image }
