import React from 'react'

import imgHero from '../assets/images/home-hero.jpg'

import {
  CoverLayout,
  CoverHeadline,
  CoverImage,
  Image,
} from '../styles/CoverLayout'
import { Headline } from '../styles/Typography'

/**
 * The home intro section.
 * @returns {StatelessComponent}
 */
const Cover = () => (
  <CoverLayout>
    <CoverHeadline>
      <Headline>
        Digitally <b>restored</b> and <b>colorized</b> <br />
        historic photos from Macedonia which will <br /> make you perceive that{' '}
        <b>history was made</b> <br />
        in a <b>colorful world.</b>
      </Headline>
    </CoverHeadline>
    <CoverImage className="cover-image">
      <Image>
        <img
          src={imgHero}
          alt="A picnic on St. George's day in 1938 on Tumbe Kafe"
        />
        <div className="overlay"></div>
      </Image>
    </CoverImage>
  </CoverLayout>
)

export default Cover
